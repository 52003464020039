import { all, call, debounce, put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { notification } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IApplication from '~Api/Application/IApplication';
import IApplicationDisbursement from '~Api/Application/IApplicationDisbursement';
import IApplicationFee from '~Api/Application/IApplicationFee';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IApplicationPropertyValuer from '~Api/Application/IApplicationPropertyValuer';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import IBorrower from '~Api/Application/IBorrower';
import IConditionalCondition from '~Api/Application/IConditionalCondition';
import IConditional from '~Api/Application/IConditional';
import IFormal from '~Api/Application/IFormal';
import IHistory from '~Api/Application/IHistory';
import INote from '~Api/Application/INote';
import {
    parseApplication,
    parseApplicationBorrower,
    parseApplicationCondition,
    parseApplicationConditionDocument,
    parseApplicationConditional,
    parseApplicationConditionalCondition,
    parseApplicationConditionalsStandardConditions,
    parseApplicationDisbursement,
    parseApplicationFee,
    parseApplicationFormal,
    parseApplicationHistory,
    parseApplicationNote,
    parseApplicationProperty,
    parseApplicationPropertyValuer,
    parseApplicationRfiItem,
    parseApplicationWarehouse,
    parseSettlementReportApplication,
} from '~Api/Application/parsers';
import {
    applicationAssignPayoutFigureRequest,
    applicationAssignRequest,
    applicationBorrowerBankruptcyCheckRequest,
    applicationBorrowerCompanyTradingHistoryCheckRequest,
    applicationBorrowerCreditCheckRequest,
    applicationBorrowersAddRequest,
    applicationBorrowersDeleteRequest,
    applicationBorrowersListRequest,
    applicationBorrowersUpdateRequest,
    applicationCloseRequest,
    applicationConditionDeleteRequest,
    applicationConditionDocumentApproveRequest,
    applicationConditionDocumentDeleteRequest,
    applicationConditionDocumentRejectRequest,
    applicationConditionDocumentResetApprovalStatusRequest,
    applicationConditionDocumentsAddRequest,
    applicationConditionDocumentsListRequest,
    applicationConditionDocumentsSolicitorAccessUpdateRequest,
    applicationConditionalApprovalRequest,
    applicationConditionalApproveRequest,
    applicationConditionalConditionAddRequest,
    applicationConditionalConditionDeleteRequest,
    applicationConditionalDeleteRequest,
    applicationConditionalGetRequest,
    applicationConditionalSendRequest,
    applicationConditionalsAddRequest,
    applicationConditionalsListRequest,
    applicationConditionalsStandardConditionsListRequest,
    applicationConditionsAddRequest,
    applicationConditionsListRequest,
    applicationDisbursementDeleteRequest,
    applicationDisbursementSuggestionsListRequest,
    applicationDisbursementUpdateRequest,
    applicationDisbursementsAddRequest,
    applicationDisbursementsListRequest,
    applicationFeeDeleteRequest,
    applicationFeeUpdateRequest,
    applicationFeesAddRequest,
    applicationFeesListRequest,
    applicationFormalAddRequest,
    applicationFormalApprovalRequest,
    applicationFormalsListRequest,
    applicationGenerateMemoRequest,
    applicationGetRequest,
    applicationHistoriesListRequest,
    applicationLegalDocumentsRequest,
    applicationMarkBrokerCommissionPaidRequest,
    applicationNewRequest,
    applicationNotesAddRequest,
    applicationNotesListRequest,
    applicationPropertiesListRequest,
    applicationPropertiesValuationsOutstandingListRequest,
    applicationPropertyGetRequest,
    applicationPropertyValuationDueDateRequest,
    applicationPropertyValuationInspectionDateRequest,
    applicationPropertyValuationReceivedDateRequest,
    applicationPropertyValuationValueRequest,
    applicationPropertyValuerMarkPaidRequest,
    applicationPropertyValuerQuoteAmountRequest,
    applicationPropertyValuerQuoteReceivedRequest,
    applicationPropertyValuerQuoteRequestedRequest,
    applicationPropertyValuerQuoteTimeFrameRequest,
    applicationPropertyValuerValuationOrderedRequest,
    applicationPropertyValuerValuationReceivedRequest,
    applicationPropertyValuersAddRequest,
    applicationPropertyValuersListRequest,
    applicationPropertyValuersUnpaidRequest,
    applicationReopenRequest,
    applicationRfiItemDeleteRequest,
    applicationRfiItemRequestedRequest,
    applicationRfiItemSuppliedRequest,
    applicationRfiItemsAddRequest,
    applicationRfiItemsListRequest,
    applicationSettlementDateRequest,
    applicationSettlementRequest,
    applicationSolicitorInstructionsSentTimeRequest,
    applicationUnderwritingRequest,
    applicationUpdateRequest,
    applicationWarehouseDeleteRequest,
    applicationWarehouseUpdateRequest,
    applicationWarehousedRequest,
    applicationWarehousesAddRequest,
    applicationWarehousesListRequest,
    applicationsCreateRenewalFromLoanRequest,
    applicationsDashboardRequest,
    applicationsListRequest,
    applicationsOutstandingBrokerCommissionsListRequest,
    applicationsSearchRequest,
    applicationsSettlementForecastRequest,
    applicationsSettlementReportRequest,
} from '~Api/Application/requests';
import IApplicationCondition from '~Api/Application/ICondition';
import IConditionDocument from '~Api/Application/IConditionDocument';
import { parseDealDocument } from '~Api/Deal/parsers';
import { dealDocumentsAddRequest } from '~Api/Deal/requests';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import { parseLoan } from '~Api/Loan/parsers';
import { loanGetRequest } from '~Api/Loan/requests';
import {
    dealDocumentSetAction,
    dealDocumentsListAction,
} from '~Deals/actions';
import history from '~history';
import { leadValueSetAction } from '~Leads/actions';
import { loanSetAction } from '~Loans/actions';
import { loanPayoutFigureSelector } from '~Loans/selectors';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    IApplicationAssignAction,
    IApplicationAssignPayoutFigureAction,
    IApplicationBorrowerBankruptcyCheckAction,
    IApplicationBorrowerCompanyTradingHistoryCheckAction,
    IApplicationBorrowerCreditCheckAction,
    IApplicationBorrowerDeleteAction,
    IApplicationBorrowerGetAction,
    IApplicationBorrowerSendAction,
    IApplicationBorrowerValueSetAction,
    IApplicationBorrowersAddAction,
    IApplicationBorrowersListAction,
    IApplicationCloseAction,
    IApplicationConditionDeleteAction,
    IApplicationConditionDocumentApproveAction,
    IApplicationConditionDocumentDeleteAction,
    IApplicationConditionDocumentRejectAction,
    IApplicationConditionDocumentResetApprovalStatusAction,
    IApplicationConditionDocumentsAddAction,
    IApplicationConditionDocumentsDownloadAction,
    IApplicationConditionDocumentsListAction,
    IApplicationConditionalApprovalAction,
    IApplicationConditionalApproveAction,
    IApplicationConditionalConditionAddAction,
    IApplicationConditionalConditionDeleteAction,
    IApplicationConditionalDeleteAction,
    IApplicationConditionalGetAction,
    IApplicationConditionalSendAction,
    IApplicationConditionalsAddAction,
    IApplicationConditionalsListAction,
    IApplicationConditionalsStandardConditionsListAction,
    IApplicationConditionsAddAction,
    IApplicationConditionsListAction,
    IApplicationDealValueSetAction,
    IApplicationDisbursementDeleteAction,
    IApplicationDisbursementSendAction,
    IApplicationDisbursementSuggestionsListAction,
    IApplicationDisbursementValueSetAction,
    IApplicationDisbursementsAddAction,
    IApplicationDisbursementsListAction,
    IApplicationFeeDeleteAction,
    IApplicationFeeSendAction,
    IApplicationFeeValueSetAction,
    IApplicationFeesAddAction,
    IApplicationFeesListAction,
    IApplicationFormalApprovalAction,
    IApplicationFormalsAddAction,
    IApplicationFormalsListAction,
    IApplicationGenerateMemoAction,
    IApplicationGetAction,
    IApplicationHistoriesListAction,
    IApplicationLegalDocumentsAction,
    IApplicationMarkBrokerCommissionPaidAction,
    IApplicationNewAction,
    IApplicationNotesAddAction,
    IApplicationNotesListAction,
    IApplicationPropertiesListAction,
    IApplicationPropertiesValuationsOutstandingListAction,
    IApplicationPropertyGetAction,
    IApplicationPropertyValuationDueDateAction,
    IApplicationPropertyValuationInspectionDateAction,
    IApplicationPropertyValuationReceivedDateAction,
    IApplicationPropertyValuationValueAction,
    IApplicationPropertyValuerMarkPaidAction,
    IApplicationPropertyValuerQuoteAmountAction,
    IApplicationPropertyValuerQuoteReceivedAction,
    IApplicationPropertyValuerQuoteRequestedAction,
    IApplicationPropertyValuerQuoteTimeFrameAction,
    IApplicationPropertyValuerValuationOrderedAction,
    IApplicationPropertyValuerValuationReceivedAction,
    IApplicationPropertyValuersListAction,
    IApplicationPropertyValuersUnpaidListAction,
    IApplicationReopenAction,
    IApplicationRfiItemDeleteAction,
    IApplicationRfiItemRequestedAction,
    IApplicationRfiItemSuppliedAction,
    IApplicationRfiItemsAddAction,
    IApplicationRfiItemsListAction,
    IApplicationSendAction,
    IApplicationSettlementAction,
    IApplicationSettlementDateAction,
    IApplicationSolicitorInstructionSentTimeAction,
    IApplicationUnderwritingAction,
    IApplicationValueSetAction,
    IApplicationWarehouseDeleteAction,
    IApplicationWarehouseSendAction,
    IApplicationWarehouseValueSetAction,
    IApplicationWarehousedAction,
    IApplicationWarehousesAddAction,
    IApplicationWarehousesListAction,
    IApplicationsCreateRenewalFromLoanAction,
    IApplicationsDashboardListAction,
    IApplicationsListAction,
    IApplicationsOutstandingBrokerCommissionsListAction,
    IApplicationsSearchAction,
    IApplicationsSettlementForecastListAction,
    IApplicationsSettlementReportListAction,
    applicationBorrowerRemoveAction,
    applicationBorrowerSendAction,
    applicationBorrowerSetAction,
    applicationBorrowersListAction,
    applicationBorrowersSetAction,
    applicationConditionDocumentRemoveAction,
    applicationConditionDocumentSetAction,
    applicationConditionDocumentsListAction,
    applicationConditionDocumentsSetAction,
    applicationConditionDocumentsUpdatingSolicitorAccessSetAction,
    applicationConditionRemoveAction,
    applicationConditionSetAction,
    applicationConditionalConditionSetAction,
    applicationConditionalsStandardConditionsSetAction as applicationConditionalCondtionStandardConditionsSetAction,
    applicationConditionalSetAction,
    applicationConditionalsListAction,
    applicationConditionalsSetAction,
    applicationConditionsSetAction,
    applicationDisbursementSendAction,
    applicationDisbursementSetAction,
    applicationDisbursementSuggestionsSetAction,
    applicationDisbursementsSetAction,
    applicationFeeRemoveAction,
    applicationFeeSendAction,
    applicationFeeSetAction,
    applicationFeesRemoveAction,
    applicationFeesSetAction,
    applicationFormalsListAction,
    applicationFormalsSetAction,
    applicationGetAction,
    applicationHistoriesSetAction,
    applicationNoteRemoveAction,
    applicationNoteSetAction,
    applicationNotesSetAction,
    applicationPropertiesSetAction,
    applicationPropertiesValuationsOutstandingSetAction,
    applicationPropertyGetAction,
    applicationPropertySetAction,
    applicationPropertyValuerSetAction,
    applicationPropertyValuersSetAction,
    applicationPropertyValuersUnpaidSetAction,
    applicationRfiItemRemoveAction,
    applicationRfiItemSetAction,
    applicationRfiItemsSetAction,
    applicationSendAction,
    applicationSetAction,
    applicationWarehouseSendAction,
    applicationWarehouseSetAction,
    applicationWarehousesSetAction,
    applicationsCreateRenewalFromLoanErrorsSetAction,
    applicationsCreateRenewalFromLoanInProgressSetAction,
    applicationsDashboardSetAction,
    applicationsOutstandingBrokerCommissionsSetAction,
    applicationsSearchResultsSetAction,
    applicationsSetAction,
    applicationsSettlementForecastSetAction,
    applicationsSettlementReportSetAction,
} from './actions';
import ApplicationsActionsEnum from './ActionsEnum';
import {
    applicationBorrowerSelector,
    applicationConditionDocumentSelector,
    applicationConditionRfiItemSelector,
    applicationDisbursementSelector,
    applicationFeeSelector,
    applicationPropertyValuerByValuerSelector,
    applicationSelector,
    applicationWarehouseSelector,
} from './selectors';
import IDocument from '~Api/Deal/IDocument';
import ConditionDocumentApprovalStatusEnum from '~Api/Application/ConditionDocumentApprovalStatusEnum';
import IRfiItem from '~Api/Application/IRfiItem';
import ISettlementReportApplication from '~Api/Application/ISettlementReportApplication';
import parameterDebounce from '~utilities/parameterDebounce';
import { authTokenSelector } from '~Auth/selectors';
import { IStandardConditionGroup } from '~Api/Application/IStandardConditionGroup';

function* applicationsCreateRenewalFromLoan(action: IApplicationsCreateRenewalFromLoanAction): Iterator<unknown> {
    yield put(applicationsCreateRenewalFromLoanInProgressSetAction(true));

    const applicationsCreateRenewalFromLoanResponse: IFetchResponse = yield call(applicationsCreateRenewalFromLoanRequest, action.applicationFee, action.applicationFeeFormat, action.applicationFeePercentage, action.brokerageFee, action.brokerageFeeFormat, action.brokerageFeePercentage, action.establishmentFee, action.establishmentFeeFormat, action.establishmentFeePercentage, action.estimatedOutlays, action.interestRate, action.legalFees, action.loanAmount, action.loanPayoutFigureUuid, action.lvr, action.maximumLvr, action.termMonths);
    if (applicationsCreateRenewalFromLoanResponse.status === 422) {
        yield put(applicationsCreateRenewalFromLoanErrorsSetAction(action.loanPayoutFigureUuid, applicationsCreateRenewalFromLoanResponse.body));
    } else {
        const application: IApplication = parseApplication(applicationsCreateRenewalFromLoanResponse.body);
        yield put(applicationSetAction(application));

        const loanPayoutFigure: ILoanPayoutFigure = yield select(loanPayoutFigureSelector, action.loanPayoutFigureUuid);

        const loanGetResponse: IFetchResponse = yield call(loanGetRequest, loanPayoutFigure.loanUuid);
        yield put(loanSetAction(parseLoan(loanGetResponse.body)));

        history.push(`/applications/${application.uuid}`);
    }

    yield put(applicationsCreateRenewalFromLoanInProgressSetAction(false));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* applicationsDashboardList(action: IApplicationsDashboardListAction): Iterator<unknown> {
    const applicationsDashboardResponse: IFetchResponse = yield call(applicationsDashboardRequest);
    const applications: IApplication[] = yield Promise.all(applicationsDashboardResponse.body.map(parseApplication));

    yield put(applicationsDashboardSetAction(applications));
}

function* applicationAssign(action: IApplicationAssignAction): Iterator<unknown> {
    const key: string = `applicationAssign ${action.applicationUuid}`;
    const message: string = 'Assign Application';

    notification.open({
        description: 'Assigning application...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationAssignResponse: IFetchResponse = yield call(applicationAssignRequest, action.applicationUuid, action.administratorUuid);
    if (applicationAssignResponse.status === 422) {
        notification.error({
            description: `There was a problem assigning the application: ${_.values(applicationAssignResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const application: IApplication = parseApplication(applicationAssignResponse.body);
        yield put(applicationSetAction(application));

        notification.success({
            description: 'The application has been assigned.',
            duration: 4.5,
            key,
            message,
        });
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* applicationsList(action: IApplicationsListAction): Iterator<unknown> {
    const rawApplications: IFetchResponse = yield call(applicationsListRequest);
    const applications: IApplication[] = yield Promise.all(rawApplications.body.map(parseApplication));

    yield put(applicationsSetAction(applications));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* applicationsSettlementForecastList(action: IApplicationsSettlementForecastListAction): Iterator<unknown> {
    const applicationsSettlementForecastResponse: IFetchResponse = yield call(applicationsSettlementForecastRequest);
    const applications: IApplication[] = yield Promise.all(applicationsSettlementForecastResponse.body.map(parseApplication));
    yield put(applicationsSettlementForecastSetAction(applications));
}

function* applicationsSettlementReportList(action: IApplicationsSettlementReportListAction): Iterator<unknown> {
    const rawSettlementReportApplications: IFetchResponse = yield call(applicationsSettlementReportRequest, action.startDate, action.endDate);
    const settlementReportApplications: ISettlementReportApplication[] = yield Promise.all(rawSettlementReportApplications.body.map(parseSettlementReportApplication));
    yield put(applicationsSettlementReportSetAction(settlementReportApplications));
}

function* applicationAssignPayoutFigure(action: IApplicationAssignPayoutFigureAction): Iterator<unknown> {
    const key: string = `applicationAssignPayoutFigure ${action.applicationUuid}`;
    const message: string = 'Use Payout Figure for Extension Application';

    notification.open({
        description: 'Using payout Figure for extension application...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationAssignPayoutFigureResponse: IFetchResponse = yield call(applicationAssignPayoutFigureRequest, action.applicationUuid, action.payoutFigureUuid);
    if (applicationAssignPayoutFigureResponse.status === 422) {
        notification.error({
            description: `There was a problem using the payout figure for the extension application: ${_.values(applicationAssignPayoutFigureResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const application: IApplication = yield parseApplication(applicationAssignPayoutFigureResponse.body);
        yield put(applicationSetAction(application));

        notification.success({
            description: 'The payout figure has been used for the extension application.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationClose(action: IApplicationCloseAction): Iterator<unknown> {
    const key: string = `applicationClose ${action.applicationUuid}`;
    const message: string = 'Close Application';

    notification.open({
        description: 'Closing application...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const application: IApplication = yield select(applicationSelector, action.applicationUuid);
    const applicationCloseResponse: IFetchResponse = yield call(applicationCloseRequest, application.uuid, action.reason, action.note);

    if (applicationCloseResponse.status === 422) {
        notification.error({
            description: `There was a problem closing the application: ${_.values(applicationCloseResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The application has been closed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationConditionalApproval(action: IApplicationConditionalApprovalAction): Iterator<unknown> {
    const rawApplication: IFetchResponse = yield call(applicationConditionalApprovalRequest, action.applicationUuid);
    const application: IApplication = parseApplication(rawApplication.body);
    yield put(applicationSetAction(application));
}

function* applicationDealValueSet(action: IApplicationDealValueSetAction): Iterator<unknown> {
    const application: IApplication = yield select(applicationSelector, action.applicationUuid);
    yield put(leadValueSetAction(application.dealUuid, action.key, action.value));
}

function* applicationFormalApproval(action: IApplicationFormalApprovalAction): Iterator<unknown> {
    const key: string = `applicationFormalApproval ${action.applicationUuid}`;
    const message: string = 'Formal Approval';
    notification.open({
        description: 'Submitting application for formal approval...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationFormalApprovalResponse: IFetchResponse = yield call(applicationFormalApprovalRequest, action.applicationUuid);
    if (applicationFormalApprovalResponse.status === 422) {
        notification.error({
            description: `There was a problem submitting the application for formal approval: ${_.values(applicationFormalApprovalResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const application: IApplication = parseApplication(applicationFormalApprovalResponse.body);
        yield put(applicationSetAction(application));

        notification.success({
            description: 'The application has been moved to Formal Approval.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationGet(action: IApplicationGetAction): Iterator<unknown> {
    const applicationGetResponse: IFetchResponse = yield call(applicationGetRequest, action.applicationUuid);
    const application: IApplication = parseApplication(applicationGetResponse.body);
    yield put(applicationSetAction(application));
}

function* applicationLegalDocuments(action: IApplicationLegalDocumentsAction): Iterator<unknown> {
    const rawApplication: IFetchResponse = yield call(applicationLegalDocumentsRequest, action.applicationUuid);
    const application: IApplication = parseApplication(rawApplication.body);
    yield put(applicationSetAction(application));
}

function* applicationNew(action: IApplicationNewAction): Iterator<unknown> {
    const rawApplication: IFetchResponse = yield call(applicationNewRequest, action.applicationUuid);
    const application: IApplication = parseApplication(rawApplication.body);
    yield put(applicationSetAction(application));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* applicationsOutstandingBrokerCommissionsList(action: IApplicationsOutstandingBrokerCommissionsListAction): Iterator<unknown> {
    const applicationsOutstandingBrokerCommissionsListResponse: IFetchResponse = yield call(applicationsOutstandingBrokerCommissionsListRequest);
    const applications: IApplication[] = yield Promise.all(applicationsOutstandingBrokerCommissionsListResponse.body.map(parseApplication));
    yield put(applicationsOutstandingBrokerCommissionsSetAction(applications));
}

function* applicationReopen(action: IApplicationReopenAction): Iterator<unknown> {
    const key: string = `applicationReopen ${dayjs().format()}`;
    const message: string = 'Reopen Application';

    notification.open({
        description: 'Reopening application...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationReopenResponse: IFetchResponse = yield call(applicationReopenRequest, action.applicationUuid);
    if (applicationReopenResponse.status === 422) {
        notification.error({
            description: `There was a problem reopening application: ${_.values(applicationReopenResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const application: IApplication = parseApplication(applicationReopenResponse.body);
        yield put(applicationSetAction(application));

        notification.success({
            description: 'The application has been reopened.',
            duration: 4.5,
            key,
            message,
        });
    }

}

function* applicationsSearch(action: IApplicationsSearchAction): Iterator<unknown> {
    const applicationsSearchResponse: IFetchResponse = yield call(applicationsSearchRequest, action.keyword);
    const applications: IApplication[] = yield Promise.all(applicationsSearchResponse.body.map(parseApplication));
    yield put(applicationsSearchResultsSetAction(applications));
}

function* applicationSend(action: IApplicationSendAction): Iterator<unknown> {
    const application: IApplication = yield select(applicationSelector, action.applicationUuid);
    yield call(applicationUpdateRequest, application);
}

function* applicationSettlement(action: IApplicationSettlementAction): Iterator<unknown> {
    const rawApplication: IFetchResponse = yield call(applicationSettlementRequest, action.applicationUuid);
    const application: IApplication = parseApplication(rawApplication.body);
    yield put(applicationSetAction(application));
}

function* applicationUnderwriting(action: IApplicationUnderwritingAction): Iterator<unknown> {
    const rawApplication: IFetchResponse = yield call(applicationUnderwritingRequest, action.applicationUuid);
    const application: IApplication = parseApplication(rawApplication.body);
    yield put(applicationSetAction(application));
}

function* applicationValueSet(action: IApplicationValueSetAction): Iterator<unknown> {
    yield put(applicationSendAction(action.applicationUuid));
}

function* applicationWarehoused(action: IApplicationWarehousedAction): Iterator<unknown> {
    const key: string = `applicationWarehoused ${action.applicationUuid}`;
    const message: string = 'Move to Settled';

    notification.open({
        description: 'Moving to settled...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const rawApplication: IFetchResponse = yield call(applicationWarehousedRequest, action.applicationUuid, action.settlementDate, action.sendEmail);
    if (rawApplication.status === 422) {
        notification.error({
            description: `There was a problem moving the application to settled: ${_.values(rawApplication.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const application: IApplication = parseApplication(rawApplication.body);
        yield put(applicationSetAction(application));

        notification.success({
            description: 'The application has been moved to settled.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationBorrowersList(action: IApplicationBorrowersListAction): Iterator<unknown> {
    const rawBorrowers: IFetchResponse = yield call(applicationBorrowersListRequest, action.applicationUuid);
    const borrowers: IBorrower[] = yield Promise.all(rawBorrowers.body.map(parseApplicationBorrower));
    yield put(applicationBorrowersSetAction(action.applicationUuid, borrowers));
}

function* applicationBorrowersAdd(action: IApplicationBorrowersAddAction): Iterator<unknown> {
    const rawBorrower: IFetchResponse = yield call(applicationBorrowersAddRequest, action.applicationUuid, action.dealBorrower, action.applicationBorrower);
    const borrower: IBorrower = parseApplicationBorrower(rawBorrower.body);
    yield put(applicationBorrowerSetAction(action.applicationUuid, borrower));
}

function* applicationBorrowerBankruptcyCheck(action: IApplicationBorrowerBankruptcyCheckAction): Iterator<unknown> {
    const key: string = `applicationBorrowerBankruptcyCheck ${action.borrowerUuid}`;

    notification.open({
        description: 'Running bankruptcy check...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Bankruptcy Check',
    });

    const rawResponse: IFetchResponse = yield call(applicationBorrowerBankruptcyCheckRequest, action.borrowerUuid, action.useMiddleName);
    if (rawResponse.status === 422) {
        notification.error({
            description: `There was a problem running the bankruptcy check: ${_.values(rawResponse.body)[0]}.`,
            duration: 0,
            key,
            message: 'Bankruptcy Check',
        });
    } else {
        const application: IApplication = yield select(applicationSelector, action.applicationUuid);

        // Fetch the docs because they should have been updated
        yield put(dealDocumentsListAction(application.dealUuid));
        yield put(applicationConditionDocumentsListAction(action.applicationUuid));
        // Fetch the borrowers because they should have been updated
        yield put(applicationBorrowersListAction(action.applicationUuid));
        // Clear the fees/outlays for the application so they are reloaded on the next view
        yield put(applicationFeesRemoveAction(action.applicationUuid));

        notification.success({
            description: 'The bankruptcy check has been run.',
            duration: 4.5,
            key,
            message: 'Bankruptcy Check',
        });
    }
}

function* applicationBorrowerCompanyTradingHistoryCheck(action: IApplicationBorrowerCompanyTradingHistoryCheckAction): Iterator<unknown> {
    const key: string = `applicationBorrowerCompanyTradingHistoryCheck ${action.borrowerUuid}`;
    const message: string = 'Company Trading History Check';

    notification.open({
        description: 'Running company trading history check...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const rawResponse: IFetchResponse = yield call(applicationBorrowerCompanyTradingHistoryCheckRequest, action.borrowerUuid);
    if (rawResponse.status === 422) {
        notification.error({
            description: `There was a problem running the company trading history check: ${_.values(rawResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        // Fetch the docs because they should have been updated
        yield put(dealDocumentsListAction(action.applicationUuid));
        yield put(applicationConditionDocumentsListAction(action.applicationUuid));
        // Fetch the borrowers because they should have been updated
        yield put(applicationBorrowersListAction(action.applicationUuid));
        // Clear the fees/outlays for the application so they are reloaded on the next view
        yield put(applicationFeesRemoveAction(action.applicationUuid));

        notification.success({
            description: 'The company trading history check has been run.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationBorrowerCreditCheck(action: IApplicationBorrowerCreditCheckAction): Iterator<unknown> {
    const key: string = `applicationBorrowerCreditCheck ${action.borrowerUuid}`;

    notification.open({
        description: 'Running credit check...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Credit Check',
    });

    const rawResponse: IFetchResponse = yield call(applicationBorrowerCreditCheckRequest, action.borrowerUuid);
    if (rawResponse.status === 422) {
        notification.error({
            description: `There was a problem running the credit check: ${_.values(rawResponse.body)[0]}.`,
            duration: 0,
            key,
            message: 'Credit Check',
        });
    } else {
        const application: IApplication = yield select(applicationSelector, action.applicationUuid);

        // Fetch the docs because they should have been updated
        yield put(dealDocumentsListAction(application.dealUuid));
        yield put(applicationConditionDocumentsListAction(action.applicationUuid));
        // Fetch the borrowers because they should have been updated
        yield put(applicationBorrowersListAction(action.applicationUuid));
        // Clear the fees/outlays for the application so they are reloaded on the next view
        yield put(applicationFeesRemoveAction(action.applicationUuid));

        notification.success({
            description: 'The credit check has been run.',
            duration: 4.5,
            key,
            message: 'Credit Check',
        });
    }
}

function* applicationBorrowerDelete(action: IApplicationBorrowerDeleteAction): Iterator<unknown> {
    yield call(applicationBorrowersDeleteRequest, action.borrowerUuid);
    yield put(applicationBorrowerRemoveAction(action.applicationUuid, action.borrowerUuid));
    history.push(`/applications/${action.applicationUuid}/borrowers`);
}

function* applicationBorrowerGet(action: IApplicationBorrowerGetAction): Iterator<unknown> {
    // @TODO: Make this actually fetch a single borrower
    yield put(applicationBorrowersListAction(action.applicationUuid));
}

function* applicationBorrowerSend(action: IApplicationBorrowerSendAction): Iterator<unknown> {
    const borrower: IBorrower = yield select(applicationBorrowerSelector, action.applicationUuid, action.borrowerUuid);
    if (borrower && borrower.uuid) {
        yield call(applicationBorrowersUpdateRequest, borrower);
    }
}

function* applicationBorrowerValueSet(action: IApplicationBorrowerValueSetAction): Iterator<unknown> {
    yield put(applicationBorrowerSendAction(action.applicationUuid, action.borrowerUuid));
}

function* applicationConditionDelete(action: IApplicationConditionDeleteAction): Iterator<unknown> {
    const key: string = `applicationConditionDelete ${dayjs().format()}`;

    notification.open({
        description: 'Deleting custom condition...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Delete Custom Condition',
    });

    yield call(applicationConditionDeleteRequest, action.conditionUuid);
    yield put(applicationConditionRemoveAction(action.applicationUuid, action.conditionUuid));

    const rfiItem: IRfiItem = yield select(applicationConditionRfiItemSelector, action.applicationUuid, action.conditionUuid);
    if (rfiItem) {
        yield put(applicationRfiItemRemoveAction(action.applicationUuid, rfiItem.uuid));
    }

    notification.success({
        description: 'The custom condition has been deleted.',
        duration: 4.5,
        key,
        message: 'Delete Custom Condition',
    });
}

function* applicationConditionsAdd(action: IApplicationConditionsAddAction): Iterator<unknown> {
    const key: string = `applicationConditionsAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding custom condition...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Custom Condition',
    });

    const preCondition: IApplicationCondition = {
        createdTime: dayjs().format(),
        name: action.name,
    };

    yield put(applicationConditionSetAction(action.applicationUuid, preCondition));

    const applicationConditionsAddResponse: IFetchResponse = yield call(applicationConditionsAddRequest, action.applicationUuid, preCondition);
    const condition: IApplicationCondition = parseApplicationCondition(applicationConditionsAddResponse.body);

    yield put(applicationConditionRemoveAction(action.applicationUuid, 'new'));
    yield put(applicationConditionSetAction(action.applicationUuid, condition));

    notification.success({
        description: 'The custom condition has been added.',
        duration: 4.5,
        key,
        message: 'Add Custom Condition',
    });
}

function* applicationConditionsList(action: IApplicationConditionsListAction): Iterator<unknown> {
    const applicationConditionsListResponse: IFetchResponse = yield call(applicationConditionsListRequest, action.applicationUuid);
    const conditions: IApplicationCondition[] = yield Promise.all(applicationConditionsListResponse.body.map(parseApplicationCondition));
    yield put(applicationConditionsSetAction(action.applicationUuid, conditions));
}

function* applicationConditionDocumentsAdd(action: IApplicationConditionDocumentsAddAction): Iterator<unknown> {
    const key: string = `applicationConditionDocumentAdd ${dayjs().format()}`;
    const message: string = 'Add Document';

    notification.open({
        description: 'Adding document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    let application: IApplication = yield select(applicationSelector, action.applicationUuid);
    if (!application) {
        const applicationGetResponse: IFetchResponse = yield call(applicationGetRequest, action.applicationUuid);
        application = parseApplication(applicationGetResponse.body);
        yield put(applicationSetAction(application));
    }

    const dealDocumentsAddResponse: IFetchResponse = yield call(dealDocumentsAddRequest, application.dealUuid, action.file);
    const document: IDocument = parseDealDocument(dealDocumentsAddResponse.body);
    yield put(dealDocumentSetAction(application.dealUuid, document));

    const applicationConditionDocumentsAddResponse: IFetchResponse = yield call(applicationConditionDocumentsAddRequest, action.applicationUuid, {
        ...action.conditionDocument,
        documentUuid: document.uuid,
    });
    if (applicationConditionDocumentsAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the document: ${_.values(applicationConditionDocumentsAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const conditionDocument: IConditionDocument = parseApplicationConditionDocument(applicationConditionDocumentsAddResponse.body);
        yield put(applicationConditionDocumentSetAction(action.applicationUuid, conditionDocument));

        notification.success({
            description: 'The document has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationConditionDocumentApprove(action: IApplicationConditionDocumentApproveAction): Iterator<unknown> {
    const key: string = `applicationConditionDocumentApprove ${action.conditionDocumentUuid}`;
    const message: string = 'Approve Document';

    notification.open({
        description: 'Approving document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationConditionDocumentApproveResponse: IFetchResponse = yield call(applicationConditionDocumentApproveRequest, action.conditionDocumentUuid);
    if (applicationConditionDocumentApproveResponse.status === 422) {
        notification.error({
            description: `There was a problem approving the document: ${_.values(applicationConditionDocumentApproveResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const conditionDocument: IConditionDocument = parseApplicationConditionDocument(applicationConditionDocumentApproveResponse.body);
        yield put(applicationConditionDocumentSetAction(action.applicationUuid, conditionDocument));

        notification.success({
            description: 'The document has been approved.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationConditionDocumentDelete(action: IApplicationConditionDocumentDeleteAction): Iterator<unknown> {
    const key: string = `applicationConditionDocumentDelete ${dayjs().format()}`;

    notification.open({
        description: 'Deleting document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Delete Document',
    });

    yield call(applicationConditionDocumentDeleteRequest, action.conditionDocumentUuid);
    yield put(applicationConditionDocumentRemoveAction(action.applicationUuid, action.conditionDocumentUuid));

    notification.success({
        description: 'The document has been deleted.',
        duration: 4.5,
        key,
        message: 'Delete Document',
    });
}

function* applicationConditionDocumentResetApprovalStatus(action: IApplicationConditionDocumentResetApprovalStatusAction): Iterator<unknown> {
    const conditionDocument: IConditionDocument = yield select(applicationConditionDocumentSelector, action.conditionDocumentUuid);
    const documentIsUnrejecting: boolean = conditionDocument.approvalStatus === ConditionDocumentApprovalStatusEnum.Rejected;
    const key: string = `applicationConditionDocumentReset${documentIsUnrejecting ? 'Unreject' : 'Unapprove'} ${action.conditionDocumentUuid}`;
    const message: string = `${documentIsUnrejecting ? 'Unreject' : 'Unapprove'} Document`;

    notification.open({
        description: `${documentIsUnrejecting ? 'Unrejecting' : 'Unapproving'} document...`,
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationConditionDocumentResetApprovalStatusResponse: IFetchResponse = yield call(applicationConditionDocumentResetApprovalStatusRequest, action.conditionDocumentUuid);
    if (applicationConditionDocumentResetApprovalStatusResponse.status === 422) {
        notification.error({
            description: `There was a problem ${documentIsUnrejecting ? 'unrejecting' : 'unapproving'} the document: ${_.values(applicationConditionDocumentResetApprovalStatusResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const updatedConditionDocument: IConditionDocument = parseApplicationConditionDocument(applicationConditionDocumentResetApprovalStatusResponse.body);
        yield put(applicationConditionDocumentSetAction(action.applicationUuid, updatedConditionDocument));

        notification.success({
            description: `The document has been ${documentIsUnrejecting ? 'unrejected' : 'unapproved'}.`,
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationConditionDocumentReject(action: IApplicationConditionDocumentRejectAction): Iterator<unknown> {
    const key: string = `applicationConditionDocumentReject ${action.conditionDocumentUuid}`;
    const message: string = 'Reject Document';

    notification.open({
        description: 'Rejecting document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationConditionDocumentRejectResponse: IFetchResponse = yield call(applicationConditionDocumentRejectRequest, action.conditionDocumentUuid);
    if (applicationConditionDocumentRejectResponse.status === 422) {
        notification.error({
            description: `There was a problem rejecting the document: ${_.values(applicationConditionDocumentRejectResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const conditionDocument: IConditionDocument = parseApplicationConditionDocument(applicationConditionDocumentRejectResponse.body);
        yield put(applicationConditionDocumentSetAction(action.applicationUuid, conditionDocument));

        notification.success({
            description: 'The document has been rejected.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationConditionDocumentsDownload(action: IApplicationConditionDocumentsDownloadAction): Iterator<unknown> {
    yield put(applicationConditionDocumentsUpdatingSolicitorAccessSetAction(true));

    const applicationConditionDocumentsSolicitorAccessResponse: IFetchResponse = yield call(applicationConditionDocumentsSolicitorAccessUpdateRequest, action.applicationUuid, action.conditionDocumentUuids);

    if (applicationConditionDocumentsSolicitorAccessResponse.status === 422) {
        notification.error({
            description: `There was a problem downloading solicitor documents : ${_.values(applicationConditionDocumentsSolicitorAccessResponse.body)[0]}.`,
            duration: 0,
            key: `applicationConditionDocumentsDownload ${action.applicationUuid}`,
            message: 'Download Solicitor Documents',
        });
    } else {
        yield put(applicationConditionDocumentsListAction(action.applicationUuid));

        const token: string = yield select(authTokenSelector);
        window.open(`${process.env.API_HOST}/applications/${action.applicationUuid}/condition-documents/zip?token=${token}`, '_blank');
    }

    yield put(applicationConditionDocumentsUpdatingSolicitorAccessSetAction(false));
}

function* applicationConditionDocumentsList(action: IApplicationConditionDocumentsListAction): Iterator<unknown> {
    const applicationConditionDocumentsListResponse: IFetchResponse = yield call(applicationConditionDocumentsListRequest, action.applicationUuid);
    const conditionDocuments: IConditionDocument[] = yield Promise.all(applicationConditionDocumentsListResponse.body.map(parseApplicationConditionDocument));
    yield put(applicationConditionDocumentsSetAction(action.applicationUuid, conditionDocuments));
}

function* applicationConditionalConditionStandardConditionsList(action: IApplicationConditionalsStandardConditionsListAction): Iterator<unknown> {
    const applicationConditionalsStandardConditionsListResponse: IFetchResponse = yield call(applicationConditionalsStandardConditionsListRequest);
    const applicationConditionalsStandardConditions: IStandardConditionGroup[] = parseApplicationConditionalsStandardConditions(applicationConditionalsStandardConditionsListResponse.body);
    yield put(applicationConditionalCondtionStandardConditionsSetAction(applicationConditionalsStandardConditions));
}

function* applicationConditionalsAdd(action: IApplicationConditionalsAddAction): Iterator<unknown> {
    history.push(`/applications/${action.applicationUuid}/conditional-approvals`);

    const key: string = `applicationConditionalsAdd ${dayjs().format()}`;
    const message: string = 'Add Conditional Approval';

    notification.open({
        description: 'Adding conditional approval...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const rawResponse: IFetchResponse = yield call(applicationConditionalsAddRequest, action.applicationUuid, action.conditions);
    if (rawResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the conditional approval: ${_.values(rawResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(applicationConditionalsListAction(action.applicationUuid));
        yield put(applicationGetAction(action.applicationUuid));

        notification.success({
            description: 'The conditional approval has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationConditionalsList(action: IApplicationConditionalsListAction): Iterator<unknown> {
    const rawConditionals: IFetchResponse = yield call(applicationConditionalsListRequest, action.applicationUuid);
    const conditionals: IConditional[] = yield Promise.all(rawConditionals.body.map(parseApplicationConditional));
    yield put(applicationConditionalsSetAction(action.applicationUuid, conditionals));
}

function* applicationConditionalApprove(action: IApplicationConditionalApproveAction): Iterator<unknown> {
    const key: string = `applicationConditionalApprove ${action.conditionalUuid}`;
    const message: string = 'Approve Conditional Approval';

    notification.open({
        description: 'Approving conditional approval...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationConditionalApproveResponse: IFetchResponse = yield call(applicationConditionalApproveRequest, action.conditionalUuid);
    if (applicationConditionalApproveResponse.status === 422) {
        notification.error({
            description: `There was a problem approving the conditional approval: ${_.values(applicationConditionalApproveResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const conditional: IConditional = parseApplicationConditional(applicationConditionalApproveResponse.body);
        yield put(applicationConditionalSetAction(conditional));

        notification.success({
            description: 'The conditional approval has been approved.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationConditionalDelete(action: IApplicationConditionalDeleteAction): Iterator<unknown> {
    const key: string = `applicationConditionalDelete ${action.conditionalUuid}`;
    const message: string = 'Delete Draft Conditional Approval';

    notification.open({
        description: 'Deleting draft conditional approval...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationConditionalDeleteResponse: IFetchResponse = yield call(applicationConditionalDeleteRequest, action.conditionalUuid);
    if (applicationConditionalDeleteResponse.status === 422) {
        notification.error({
            description: `There was a problem deleting the conditional approval: ${_.values(applicationConditionalDeleteResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The conditional approval has been deleted.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationConditionalGet(action: IApplicationConditionalGetAction): Iterator<unknown> {
    const applicationConditionalGetResponse: IFetchResponse = yield call(applicationConditionalGetRequest, action.conditionalUuid);
    const conditional: IConditional = parseApplicationConditional(applicationConditionalGetResponse.body);
    yield put(applicationConditionalSetAction(conditional));
}

function* applicationConditionalSend(action: IApplicationConditionalSendAction): Iterator<unknown> {
    const key: string = `applicationConditionalSend ${action.conditionalUuid} ${action.email}`;
    const message: string = 'Send Conditional Approval';

    notification.open({
        description: 'Sending conditional approval...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const sendResponse: IFetchResponse = yield call(applicationConditionalSendRequest, action.conditionalUuid, action.email, action.firstName, action.lastName);
    if (sendResponse.status === 422) {
        notification.error({
            description: `There was a problem sending the conditional approval: ${_.values(sendResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The conditional approval has been sent.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationConditionalConditionAdd(action: IApplicationConditionalConditionAddAction): Iterator<unknown> {
    const applicationConditionalConditionAddResponse: IFetchResponse = yield call(applicationConditionalConditionAddRequest, action.conditionalUuid, action.condition);
    const applicationConditionalCondition: IConditionalCondition = parseApplicationConditionalCondition(applicationConditionalConditionAddResponse.body);
    yield put(applicationConditionalConditionSetAction(action.conditionalUuid, applicationConditionalCondition));
}

function* applicationConditionalConditionDelete(action: IApplicationConditionalConditionDeleteAction): Iterator<unknown> {
    yield call(applicationConditionalConditionDeleteRequest, action.condition.uuid);
}

function* applicationDisbursementsAdd(action: IApplicationDisbursementsAddAction): Iterator<unknown> {
    const key: string = `applicationDisbursementsAdd ${action.applicationUuid}`;

    notification.open({
        description: 'Adding disbursement...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Disbursement',
    });

    const applicationDisbursementAddResponse: IFetchResponse = yield call(applicationDisbursementsAddRequest, action.applicationUuid, action.disbursement);
    const applicationDisbursement: IApplicationDisbursement = parseApplicationDisbursement(applicationDisbursementAddResponse.body);
    yield put(applicationDisbursementSetAction(applicationDisbursement));

    notification.success({
        description: 'The disbursement has been added.',
        duration: 4.5,
        key,
        message: 'Add Disbursement',
    });
}

function* applicationDisbursementDelete(action: IApplicationDisbursementDeleteAction): Iterator<unknown> {
    const key: string = `applicationDisbursementDelete ${action.disbursementUuid}`;

    notification.open({
        description: 'Deleting disbursement...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Delete Disbursement',
    });

    yield call(applicationDisbursementDeleteRequest, action.disbursementUuid);

    notification.success({
        description: 'The disbursement has been deleted.',
        duration: 4.5,
        key,
        message: 'Delete Disbursement',
    });
}

function* applicationDisbursementSend(action: IApplicationDisbursementSendAction): Iterator<unknown> {
    const applicationDisbursement: IApplicationDisbursement = yield select(applicationDisbursementSelector, action.disbursementUuid);
    const applicationDisbursementUpdateResponse: IFetchResponse = yield call(applicationDisbursementUpdateRequest, applicationDisbursement);
    if (applicationDisbursementUpdateResponse.status === 422) {
        notification.error({
            description: `There was a problem updating the disbursement: ${_.values(applicationDisbursementUpdateResponse.body)[0]}.`,
            duration: 0,
            key: `applicationDisbursementUpdate ${action.disbursementUuid}`,
            message: 'Update Application Disbursement',
        });
    }
}

function* applicationDisbursementValueSet(action: IApplicationDisbursementValueSetAction): Iterator<unknown> {
    yield put(applicationDisbursementSendAction(action.disbursementUuid));
}

function* applicationDisbursementsList(action: IApplicationDisbursementsListAction): Iterator<unknown> {
    const applicationDisbursementsListResponse: IFetchResponse = yield call(applicationDisbursementsListRequest, action.applicationUuid);
    const disbursements: IApplicationDisbursement[] = yield Promise.all(applicationDisbursementsListResponse.body.map(parseApplicationDisbursement));
    yield put(applicationDisbursementsSetAction(action.applicationUuid, disbursements));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
function* applicationDisbursementSuggestionsList(action: IApplicationDisbursementSuggestionsListAction): Iterator<unknown> {
    const applicationDisbursementSuggestionsListResponse: IFetchResponse = yield call(applicationDisbursementSuggestionsListRequest);
    yield put(applicationDisbursementSuggestionsSetAction(applicationDisbursementSuggestionsListResponse.body.descriptions, applicationDisbursementSuggestionsListResponse.body.payeeNames));
}

function* applicationFeesAdd(action: IApplicationFeesAddAction): Iterator<unknown> {
    const key: string = `applicationFeesAdd ${action.applicationUuid}`;

    notification.open({
        description: 'Adding fee/outlay...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Fee/Outlay',
    });

    const rawApplicationFee: IFetchResponse = yield call(applicationFeesAddRequest, action.applicationUuid, action.fee);
    const applicationFee: IApplicationFee = parseApplicationFee(rawApplicationFee.body);
    yield put(applicationFeeSetAction(applicationFee));

    yield put(applicationBorrowersListAction(action.applicationUuid));

    notification.success({
        description: 'The fee/outlay has been added.',
        duration: 4.5,
        key,
        message: 'Add Fee/Outlay',
    });
}

function* applicationFeeDelete(action: IApplicationFeeDeleteAction): Iterator<unknown> {
    const key: string = `applicationFeeDelete ${action.applicationFeeUuid}`;

    notification.open({
        description: 'Deleting fee/outlay...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Delete Fee/Outlay',
    });

    yield call(applicationFeeDeleteRequest, action.applicationFeeUuid);
    yield put(applicationFeeRemoveAction(action.applicationFeeUuid));

    notification.success({
        description: 'The fee/outlay has been deleted.',
        duration: 4.5,
        key,
        message: 'Delete Fee/Outlay',
    });
}

function* applicationFeeSend(action: IApplicationFeeSendAction): Iterator<unknown> {
    const applicationFee: IApplicationFee = yield select(applicationFeeSelector, action.applicationFeeUuid);
    yield call(applicationFeeUpdateRequest, applicationFee);
}

function* applicationFeeValueSet(action: IApplicationFeeValueSetAction): Iterator<unknown> {
    yield put(applicationFeeSendAction(action.applicationFeeUuid));
}

function* applicationFeesList(action: IApplicationFeesListAction): Iterator<unknown> {
    const rawFees: IFetchResponse = yield call(applicationFeesListRequest, action.applicationUuid);
    const fees: IApplicationFee[] = yield Promise.all(rawFees.body.map(parseApplicationFee));
    yield put(applicationFeesSetAction(action.applicationUuid, fees));
}

function* applicationFormalsAdd(action: IApplicationFormalsAddAction): Iterator<unknown> {
    const key: string = `applicationFormalIssue ${dayjs().format()}`;
    const message: string = 'Issue Formal Approval';

    notification.open({
        description: 'Issuing formal approval...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const rawResponse: IFetchResponse = yield call(applicationFormalAddRequest, action.applicationUuid);
    if (rawResponse.status === 422) {
        notification.error({
            description: `There was a problem issuing a new formal approval: ${_.values(rawResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(applicationFormalsListAction(action.applicationUuid));

        notification.success({
            description: 'The formal approval has been issued.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationFormalsList(action: IApplicationFormalsListAction): Iterator<unknown> {
    const rawFormals: IFetchResponse = yield call(applicationFormalsListRequest, action.applicationUuid);
    const formals: IFormal[] = yield Promise.all(rawFormals.body.map(parseApplicationFormal));
    yield put(applicationFormalsSetAction(action.applicationUuid, formals));
}

function* applicationHistoriesList(action: IApplicationHistoriesListAction): Iterator<unknown> {
    const applicationHistoriesListResponse: IFetchResponse = yield call(applicationHistoriesListRequest, action.applicationUuid);
    const histories: IHistory[] = yield Promise.all(applicationHistoriesListResponse.body.map(parseApplicationHistory));
    yield put(applicationHistoriesSetAction(action.applicationUuid, histories));
}

function* applicationNotesAdd(action: IApplicationNotesAddAction): Iterator<unknown> {
    const key: string = `applicationNotesAdd ${dayjs().format()}`;
    const message: string = 'Add Note';

    notification.open({
        description: 'Adding note...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const currentAdministrator: IAdministrator = yield select(currentAdministratorSelector);

    const preNote: INote = {
        administratorUuid: currentAdministrator.uuid,
        createdTime: dayjs().format(),
        note: action.note,
    };

    yield put(applicationNoteSetAction(action.applicationUuid, preNote));

    const applicationNotesAddResponse: IFetchResponse = yield call(applicationNotesAddRequest, action.applicationUuid, preNote);
    const note: INote = parseApplicationNote(applicationNotesAddResponse.body);

    yield put(applicationNoteRemoveAction(action.applicationUuid, 'new'));
    yield put(applicationNoteSetAction(action.applicationUuid, note));

    notification.success({
        description: 'The note has been added.',
        duration: 4.5,
        key,
        message,
    });
}

function* applicationNotesList(action: IApplicationNotesListAction): Iterator<unknown> {
    const applicationNotesListResponse: IFetchResponse = yield call(applicationNotesListRequest, action.applicationUuid);
    const notes: INote[] = yield Promise.all(applicationNotesListResponse.body.map(parseApplicationNote));
    yield put(applicationNotesSetAction(action.applicationUuid, notes));
}

function* applicationMarkBrokerCommissionPaid(action: IApplicationMarkBrokerCommissionPaidAction): Iterator<unknown> {
    const key: string = `applicationMarkBrokerCommissionPaid ${action.applicationUuid}`;
    const message: string = 'Mark Broker Invoice As Paid';

    notification.open({
        description: 'Marking broker invoice as paid...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationMarkBrokerCommissionPaidResponse: IFetchResponse = yield call(applicationMarkBrokerCommissionPaidRequest, action.applicationUuid);
    if (applicationMarkBrokerCommissionPaidResponse.status === 422) {
        notification.error({
            description: `There was a problem marking the broker invoice as paid: ${_.values(applicationMarkBrokerCommissionPaidResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const application: IApplication = parseApplication(applicationMarkBrokerCommissionPaidResponse.body);
        yield put(applicationSetAction(application));

        notification.success({
            description: 'The broker invoice has been marked as paid.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationGenerateMemo(action: IApplicationGenerateMemoAction): Iterator<unknown> {
    const key: string = `applicationGenerateMemo ${dayjs().format()}`;
    const message: string = 'Generate Credit Memo';

    notification.open({
        description: 'Generating credit memo...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationGenerateMemoResponse: IFetchResponse = yield call(applicationGenerateMemoRequest, action.applicationUuid);
    if (applicationGenerateMemoResponse.status === 422) {
        notification.error({
            description: `There was a problem generating the credit memo: ${_.values(applicationGenerateMemoResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(applicationConditionDocumentsListAction(action.applicationUuid));

        notification.success({
            description: 'Successfully generated credit memo.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationSettlementDate(action: IApplicationSettlementDateAction): Iterator<unknown> {
    const key: string = `applicationSettlementDate ${dayjs().format()}`;

    notification.open({
        description: 'Changing settlement date...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Change Settlement Date',
    });

    yield call(applicationSettlementDateRequest, action.applicationUuid, action.settlementDate);

    notification.success({
        description: 'The settlement date has been changed.',
        duration: 4.5,
        key,
        message: 'Change Settlement Date',
    });
}

function* applicationSolicitorInstructionsSentTime(action: IApplicationSolicitorInstructionSentTimeAction): Iterator<unknown> {
    const key: string = `applicationSolicitorInstructionSentTime ${dayjs().format()}`;
    const message: string = 'Send Solicitor Instructions';

    notification.open({
        description: 'Updating solicitor instructions sent time...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationSolicitorInstructionsSentTimeResponse: IFetchResponse = yield call(applicationSolicitorInstructionsSentTimeRequest, action.applicationUuid, action.solicitorInstructionSentTime);
    if (applicationSolicitorInstructionsSentTimeResponse.status === 422) {
        notification.error({
            description: `There was a problem updating the solicitor instructions sent time: ${_.values(applicationSolicitorInstructionsSentTimeResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const application: IApplication = parseApplication(applicationSolicitorInstructionsSentTimeResponse.body);
        yield put(applicationSetAction(application));

        notification.success({
            description: 'Solicitor instructions sent time successfully updated.',
            duration: 4.5,
            key,
            message,
        });
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* applicationPropertiesValuationsOutstandingList(action: IApplicationPropertiesValuationsOutstandingListAction): Iterator<unknown> {
    const applicationPropertiesValuationsOutstandingListResponse: IFetchResponse = yield call(applicationPropertiesValuationsOutstandingListRequest);
    const applicationProperties: IApplicationProperty[] = yield Promise.all(applicationPropertiesValuationsOutstandingListResponse.body.map(parseApplicationProperty));
    yield put(applicationPropertiesValuationsOutstandingSetAction(applicationProperties));
}

function* applicationPropertyGet(action: IApplicationPropertyGetAction): Iterator<unknown> {
    const applicationPropertyGetResponse: IFetchResponse = yield call(applicationPropertyGetRequest, action.applicationPropertyUuid);
    const property: IApplicationProperty = yield parseApplicationProperty(applicationPropertyGetResponse.body);
    yield put(applicationPropertySetAction(property));
}

function* applicationPropertiesList(action: IApplicationPropertiesListAction): Iterator<unknown> {
    const applicationPropertiesListResponse: IFetchResponse = yield call(applicationPropertiesListRequest, action.applicationUuid);
    const applicationProperties: IApplicationProperty[] = yield Promise.all(applicationPropertiesListResponse.body.map(parseApplicationProperty));
    yield put(applicationPropertiesSetAction(action.applicationUuid, applicationProperties));
}

function* applicationPropertyValuationDueDate(action: IApplicationPropertyValuationDueDateAction): Iterator<unknown> {
    const key: string = `applicationPropertyUpdateValuationDueDate ${dayjs().format()}`;
    const message: string = 'Update Valuation Due Date';

    notification.open({
        description: 'Updating property valuation due date...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationPropertyValuationDueDateResponse: IFetchResponse = yield call(applicationPropertyValuationDueDateRequest, action.applicationPropertyUuid, action.valuationDueDate);
    if (applicationPropertyValuationDueDateResponse.status === 422) {
        notification.error({
            description: `There was a problem updating the valuation due date: ${_.values(applicationPropertyValuationDueDateResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const property: IApplicationProperty = parseApplicationProperty(applicationPropertyValuationDueDateResponse.body);
        yield put(applicationPropertySetAction(property));

        notification.success({
            description: 'Valuation due date successfully updated for property.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationPropertyValuationInspectionDate(action: IApplicationPropertyValuationInspectionDateAction): Iterator<unknown> {
    const key: string = `applicationPropertyValuationInspectionDate ${dayjs().format()}`;
    const message: string = 'Change Valuation Inspection Date';

    notification.open({
        description: 'Changing valuation inspection date...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationPropertyValuationInspectionDateResponse: IFetchResponse = yield call(applicationPropertyValuationInspectionDateRequest, action.applicationPropertyUuid, action.valuationInspectionDate);
    if (applicationPropertyValuationInspectionDateResponse.status === 422) {
        notification.error({
            description: `There was a problem updating the valuation inspection date: ${_.values(applicationPropertyValuationInspectionDateResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const property: IApplicationProperty = parseApplicationProperty(applicationPropertyValuationInspectionDateResponse.body);
        yield put(applicationPropertySetAction(property));

        notification.success({
            description: 'The valuation inspection date has been changed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationPropertyValuationReceivedDate(action: IApplicationPropertyValuationReceivedDateAction): Iterator<unknown> {
    const key: string = `applicationPropertyUpdateValuationReceivedDate ${dayjs().format()}`;
    const message: string = 'Update Valuation Received Date';

    notification.open({
        description: 'Updating property valuation received date...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationPropertyValuationReceivedDateResponse: IFetchResponse = yield call(applicationPropertyValuationReceivedDateRequest, action.applicationPropertyUuid, action.valuationReceivedDate);
    if (applicationPropertyValuationReceivedDateResponse.status === 422) {
        notification.error({
            description: `There was a problem updating the valuation received date: ${_.values(applicationPropertyValuationReceivedDateResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const property: IApplicationProperty = parseApplicationProperty(applicationPropertyValuationReceivedDateResponse.body);
        yield put(applicationPropertySetAction(property));

        notification.success({
            description: 'Valuation received date successfully updated for property.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationPropertyValuationValue(action: IApplicationPropertyValuationValueAction): Iterator<unknown> {
    const key: string = `applicationPropertyUpdateValuationValue ${dayjs().format()}`;
    const message: string = 'Update Valuation Value';

    notification.open({
        description: 'Updating property valuation value...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationPropertyValuationValueResponse: IFetchResponse = yield call(applicationPropertyValuationValueRequest, action.applicationPropertyUuid, action.valuationValue);
    if (applicationPropertyValuationValueResponse.status === 422) {
        notification.error({
            description: `There was a problem updating the valuation value: ${_.values(applicationPropertyValuationValueResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const property: IApplicationProperty = parseApplicationProperty(applicationPropertyValuationValueResponse.body);
        yield put(applicationPropertySetAction(property));

        notification.success({
            description: 'Valuation value successfully updated for property.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationPropertyValuerQuoteAmount(action: IApplicationPropertyValuerQuoteAmountAction): Iterator<unknown> {
    const key: string = `applicationPropertyValuerQuoteAmount ${dayjs().format()}`;
    const message: string = 'Updating Property Valuer Quote Amount';

    notification.open({
        description: 'Updating property valuer quote amount...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationPropertyValuerQuoteAmountResponse: IFetchResponse = yield call(applicationPropertyValuerQuoteAmountRequest, action.propertyValuerUuid, action.quoteAmount);
    if (applicationPropertyValuerQuoteAmountResponse.status === 422) {
        notification.error({
            description: `There was a problem updating the property valuer quote amount: ${_.values(applicationPropertyValuerQuoteAmountResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const propertyValuer: IApplicationPropertyValuer = yield parseApplicationPropertyValuer(applicationPropertyValuerQuoteAmountResponse.body);
        yield put(applicationPropertyValuerSetAction(action.applicationPropertyUuid, propertyValuer));

        notification.success({
            description: 'Property valuer quote amount successfully updated.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationPropertyValuerQuoteReceived(action: IApplicationPropertyValuerQuoteReceivedAction): Iterator<unknown> {
    const key: string = `applicationPropertyValuerQuoteReceived ${dayjs().format()}`;
    const message: string = 'Enter Property Valuation Quote';

    notification.open({
        description: 'Entering property valuation quote information...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    let propertyValuer: IApplicationPropertyValuer = yield select(applicationPropertyValuerByValuerSelector, action.applicationPropertyUuid, action.valuerUuid);
    if (!propertyValuer) {
        const applicationPropertyValuersAddResponse: IFetchResponse = yield call(applicationPropertyValuersAddRequest, action.applicationPropertyUuid, action.valuerUuid);
        propertyValuer = yield parseApplicationPropertyValuer(applicationPropertyValuersAddResponse.body);
        yield put(applicationPropertyValuerSetAction(action.applicationPropertyUuid, propertyValuer));
    }

    const applicationPropertyValuerQuoteReceivedResponse: IFetchResponse = yield call(applicationPropertyValuerQuoteReceivedRequest, propertyValuer.uuid, action.quoteAmount, action.quoteReceivedDateTime, action.quoteTimeFrameDays);
    if (applicationPropertyValuerQuoteReceivedResponse.status === 422) {
        notification.error({
            description: `There was a problem entering the valuation quote information: ${_.values(applicationPropertyValuerQuoteReceivedResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const updatedPropertyValuer: IApplicationPropertyValuer = parseApplicationPropertyValuer(applicationPropertyValuerQuoteReceivedResponse.body);
        yield put(applicationPropertyValuerSetAction(action.applicationPropertyUuid, updatedPropertyValuer));

        notification.success({
            description: 'Quote information successfully entered for valuer.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationPropertyValuerQuoteRequested(action: IApplicationPropertyValuerQuoteRequestedAction): Iterator<unknown> {
    const key: string = `applicationPropertyValuerQuoteRequested ${dayjs().format()}`;
    const message: string = 'Moving Property Valuer to Quote Requested';

    notification.open({
        description: 'Moving property valuer to quote requested...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    let propertyValuer: IApplicationPropertyValuer = yield select(applicationPropertyValuerByValuerSelector, action.applicationPropertyUuid, action.valuerUuid);
    if (!propertyValuer) {
        const applicationPropertyValuersAddResponse: IFetchResponse = yield call(applicationPropertyValuersAddRequest, action.applicationPropertyUuid, action.valuerUuid);
        propertyValuer = yield parseApplicationPropertyValuer(applicationPropertyValuersAddResponse.body);
        yield put(applicationPropertyValuerSetAction(action.applicationPropertyUuid, propertyValuer));
    }

    const applicationPropertyValuerQuoteRequestedResponse: IFetchResponse = yield call(applicationPropertyValuerQuoteRequestedRequest, propertyValuer.uuid, action.quoteRequestedDateTime);
    if (applicationPropertyValuerQuoteRequestedResponse.status === 422) {
        notification.error({
            description: `There was a problem moving the property valuer to quote requested: ${_.values(applicationPropertyValuerQuoteRequestedResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const updatedPropertyValuer: IApplicationPropertyValuer = parseApplicationPropertyValuer(applicationPropertyValuerQuoteRequestedResponse.body);
        yield put(applicationPropertyValuerSetAction(action.applicationPropertyUuid, updatedPropertyValuer));

        notification.success({
            description: 'Property valuer successfully moved to quote requested.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationPropertyValuerQuoteTimeFrame(action: IApplicationPropertyValuerQuoteTimeFrameAction): Iterator<unknown> {
    const key: string = `applicationPropertyValuerQuoteTimeFrame ${dayjs().format()}`;
    const message: string = 'Updating Property Valuer Quote Time Frame';

    notification.open({
        description: 'Updating property valuer quote time frame...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationPropertyValuerQuoteTimeFrameResponse: IFetchResponse = yield call(applicationPropertyValuerQuoteTimeFrameRequest, action.propertyValuerUuid, action.quoteTimeFrameDays);
    if (applicationPropertyValuerQuoteTimeFrameResponse.status === 422) {
        notification.error({
            description: `There was a problem updating the property valuer quote time frame: ${_.values(applicationPropertyValuerQuoteTimeFrameResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const propertyValuer: IApplicationPropertyValuer = yield parseApplicationPropertyValuer(applicationPropertyValuerQuoteTimeFrameResponse.body);
        yield put(applicationPropertyValuerSetAction(action.applicationPropertyUuid, propertyValuer));

        notification.success({
            description: 'Property valuer quote time frame successfully updated.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationPropertyValuerValuationOrdered(action: IApplicationPropertyValuerValuationOrderedAction): Iterator<unknown> {
    const key: string = `applicationPropertyValuerValuationOrdered ${dayjs().format()}`;
    const message: string = 'Moving Property Valuer to Valuation Ordered';

    notification.open({
        description: 'Moving property valuer to valuation ordered...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    let propertyValuer: IApplicationPropertyValuer = yield select(applicationPropertyValuerByValuerSelector, action.applicationPropertyUuid, action.valuerUuid);
    if (!propertyValuer) {
        const applicationPropertyValuersAddResponse: IFetchResponse = yield call(applicationPropertyValuersAddRequest, action.applicationPropertyUuid, action.valuerUuid);
        propertyValuer = yield parseApplicationPropertyValuer(applicationPropertyValuersAddResponse.body);
        yield put(applicationPropertyValuerSetAction(action.applicationPropertyUuid, propertyValuer));
    }

    const applicationPropertyValuerValuationOrderedResponse: IFetchResponse = yield call(applicationPropertyValuerValuationOrderedRequest, propertyValuer.uuid, action.dueDate, action.valuationOrderedDateTime);
    if (applicationPropertyValuerValuationOrderedResponse.status === 422) {
        notification.error({
            description: `There was a problem moving the property valuer to valuation ordered: ${_.values(applicationPropertyValuerValuationOrderedResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const updatedPropertyValuer: IApplicationPropertyValuer = parseApplicationPropertyValuer(applicationPropertyValuerValuationOrderedResponse.body);
        yield put(applicationPropertyValuerSetAction(action.applicationPropertyUuid, updatedPropertyValuer));
        yield put(applicationPropertyGetAction(action.applicationPropertyUuid));

        notification.success({
            description: 'Property valuer successfully moved to valuation ordered.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationPropertyValuerValuationReceived(action: IApplicationPropertyValuerValuationReceivedAction): Iterator<unknown> {
    const key: string = `applicationPropertyValuerValuationReceived ${dayjs().format()}`;
    const message: string = 'Entering Valuer Property Valuation';

    notification.open({
        description: 'Entering valuer property valuation...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    let propertyValuer: IApplicationPropertyValuer = yield select(applicationPropertyValuerByValuerSelector, action.applicationPropertyUuid, action.valuerUuid);
    if (!propertyValuer) {
        const applicationPropertyValuersAddResponse: IFetchResponse = yield call(applicationPropertyValuersAddRequest, action.applicationPropertyUuid, action.valuerUuid);
        propertyValuer = yield parseApplicationPropertyValuer(applicationPropertyValuersAddResponse.body);
        yield put(applicationPropertyValuerSetAction(action.applicationPropertyUuid, propertyValuer));
    }

    const applicationPropertyValuerValuationReceivedResponse: IFetchResponse = yield call(applicationPropertyValuerValuationReceivedRequest, propertyValuer.uuid, action.valuationInspectionDate, action.valuationReceivedDateTime, action.valuationValue);
    if (applicationPropertyValuerValuationReceivedResponse.status === 422) {
        notification.error({
            description: `There was a problem entering the valuation: ${_.values(applicationPropertyValuerValuationReceivedResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const updatedPropertyValuer: IApplicationPropertyValuer = parseApplicationPropertyValuer(applicationPropertyValuerValuationReceivedResponse.body);
        yield put(applicationPropertyValuerSetAction(action.applicationPropertyUuid, updatedPropertyValuer));
        yield put(applicationPropertyGetAction(action.applicationPropertyUuid));

        notification.success({
            description: 'Property valuation successfully entered.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationPropertyValuersList(action: IApplicationPropertyValuersListAction): Iterator<unknown> {
    const applicationPropertyValuersListResponse: IFetchResponse = yield call(applicationPropertyValuersListRequest, action.applicationPropertyUuid);
    const valuers: IApplicationPropertyValuer[] = yield Promise.all(applicationPropertyValuersListResponse.body.map(parseApplicationPropertyValuer));
    yield put(applicationPropertyValuersSetAction(action.applicationPropertyUuid, valuers));
}

function* applicationPropertyValuerMarkPaid(action: IApplicationPropertyValuerMarkPaidAction): Iterator<unknown> {
    const key: string = `applicationPropertyValuerMarkPaid ${action.applicationPropertyValuerUuid}`;
    const message: string = 'Mark Invoice As Paid';
    notification.open({
        description: 'Marking invoice as paid...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationPropertyValuerMarkPaidResponse: IFetchResponse = yield call(applicationPropertyValuerMarkPaidRequest, action.applicationPropertyValuerUuid);

    if (applicationPropertyValuerMarkPaidResponse.status === 422) {
        notification.error({
            description: `There was a problem marking the invoice as paid: ${_.values(applicationPropertyValuerMarkPaidResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The invoice has been marked as paid.',
            duration: 4.5,
            key,
            message,
        });
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* applicationPropertyValuersUnpaidList(action: IApplicationPropertyValuersUnpaidListAction): Iterator<unknown> {
    const applicationPropertyValuersUnpaidListResponse: IFetchResponse = yield call(applicationPropertyValuersUnpaidRequest);
    const valuers: IApplicationPropertyValuer[] = yield Promise.all(applicationPropertyValuersUnpaidListResponse.body.map(parseApplicationPropertyValuer));
    yield put(applicationPropertyValuersUnpaidSetAction(valuers));
}

function* applicationRfiItemDelete(action: IApplicationRfiItemDeleteAction): Iterator<unknown> {
    const key: string = `applicationRfiItemDelete ${action.rfiItemUuid}`;
    const message: string = 'Delete Request for Information';

    notification.open({
        description: 'Deleting request for information...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationRfiItemDeleteResponse: IFetchResponse = yield call(applicationRfiItemDeleteRequest, action.rfiItemUuid);

    if (applicationRfiItemDeleteResponse.status === 422) {
        notification.error({
            description: `There was a problem deleting the request for information: ${_.values(applicationRfiItemDeleteResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(applicationRfiItemRemoveAction(action.applicationUuid, action.rfiItemUuid));

        notification.success({
            description: 'The request for information has been deleted.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationRfiItemRequested(action: IApplicationRfiItemRequestedAction): Iterator<unknown> {
    const key: string = `applicationRfiItemRequested ${action.rfiItemUuid}`;
    const message: string = 'Request for Information';

    notification.open({
        description: 'Changing status to requested...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationRfiItemRequestedResponse: IFetchResponse = yield call(applicationRfiItemRequestedRequest, action.rfiItemUuid);

    if (applicationRfiItemRequestedResponse.status === 422) {
        notification.error({
            description: `There was a problem changing the status to requested: ${_.values(applicationRfiItemRequestedResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const rfiItem: IRfiItem = parseApplicationRfiItem(applicationRfiItemRequestedResponse.body);
        yield put(applicationRfiItemSetAction(rfiItem.applicationUuid, rfiItem));

        notification.success({
            description: 'Status changed to requested.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationRfiItemSupplied(action: IApplicationRfiItemSuppliedAction): Iterator<unknown> {
    const key: string = `applicationRfiItemSupplied ${action.rfiItemUuid}`;
    const message: string = 'Request for Information Supplied';

    notification.open({
        description: 'Changing status to supplied...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationRfiItemSuppliedResponse: IFetchResponse = yield call(applicationRfiItemSuppliedRequest, action.rfiItemUuid);

    if (applicationRfiItemSuppliedResponse.status === 422) {
        notification.error({
            description: `There was a problem changing the status to supplied: ${_.values(applicationRfiItemSuppliedResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const rfiItem: IRfiItem = parseApplicationRfiItem(applicationRfiItemSuppliedResponse.body);
        yield put(applicationRfiItemSetAction(rfiItem.applicationUuid, rfiItem));

        notification.success({
            description: 'Status changed to supplied.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationRfiItemsAdd(action: IApplicationRfiItemsAddAction): Iterator<unknown> {
    const key: string = `applicationRfiItemsAdd ${dayjs().format()}`;
    const message: string = 'Add Request for Information';

    notification.open({
        description: 'Adding Request for Information...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationRfiItemsAddResponse: IFetchResponse = yield call(applicationRfiItemsAddRequest, action.applicationUuid, action.applicationBorrowerUuid, action.applicationConditionUuid, action.applicationPropertyUuid, action.conditionType);
    if (applicationRfiItemsAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the request for information: ${_.values(applicationRfiItemsAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const rfiItem: IRfiItem = parseApplicationRfiItem(applicationRfiItemsAddResponse.body);
        yield put(applicationRfiItemSetAction(action.applicationUuid, rfiItem));

        notification.success({
            description: 'The request for information has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationRfiItemsList(action: IApplicationRfiItemsListAction): Iterator<unknown> {
    const applicationRfiItemsListResponse: IFetchResponse = yield call(applicationRfiItemsListRequest, action.applicationUuid);
    const rfiItems: IRfiItem[] = yield Promise.all(applicationRfiItemsListResponse.body.map(parseApplicationRfiItem));
    yield put(applicationRfiItemsSetAction(action.applicationUuid, rfiItems));
}

function* applicationWarehousesAdd(action: IApplicationWarehousesAddAction): Iterator<unknown> {
    const key: string = `applicationWarehousesAdd ${action.warehouse.uuid}`;
    const message: string = 'Add Warehouse';

    notification.open({
        description: 'Adding warehouse...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationWarehousesAddResponse: IFetchResponse = yield call(applicationWarehousesAddRequest, action.applicationUuid, action.warehouse);
    if (applicationWarehousesAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the warehouse: ${_.values(applicationWarehousesAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const applicationWarehouse: IApplicationWarehouse = parseApplicationWarehouse(applicationWarehousesAddResponse.body);
        yield put(applicationWarehouseSetAction(applicationWarehouse));

        notification.success({
            description: 'The warehouse has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationWarehouseDelete(action: IApplicationWarehouseDeleteAction): Iterator<unknown> {
    const key: string = `applicationWarehouseDelete ${action.applicationWarehouseUuid}`;
    const message: string = 'Delete Warehouse';

    notification.open({
        description: 'Deleting warehouse...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const applicationWarehouseDeleteResponse: IFetchResponse = yield call(applicationWarehouseDeleteRequest, action.applicationWarehouseUuid);

    if (applicationWarehouseDeleteResponse.status === 422) {
        notification.error({
            description: `There was a problem deleting the warehouse: ${_.values(applicationWarehouseDeleteResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        notification.success({
            description: 'The warehouse has been deleted.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* applicationWarehouseSend(action: IApplicationWarehouseSendAction): Iterator<unknown> {
    const applicationWarehouse: IApplicationWarehouse = yield select(applicationWarehouseSelector, action.warehouseUuid);
    yield call(applicationWarehouseUpdateRequest, applicationWarehouse);
}

function* applicationWarehouseValueSet(action: IApplicationWarehouseValueSetAction): Iterator<unknown> {
    yield put(applicationWarehouseSendAction(action.warehouseUuid));
}

function* applicationWarehousesList(action: IApplicationWarehousesListAction): Iterator<unknown> {
    const applicationWarehousesListResponse: IFetchResponse = yield call(applicationWarehousesListRequest, action.applicationUuid);
    const applicationWarehouses: IApplicationWarehouse[] = yield Promise.all(applicationWarehousesListResponse.body.map(parseApplicationWarehouse));
    yield put(applicationWarehousesSetAction(action.applicationUuid, applicationWarehouses));
}

export function* ApplicationsSagas(): Iterator<unknown> {
    yield all([
        takeEvery(ApplicationsActionsEnum.ApplicationsCreateRenewalFromLoan, applicationsCreateRenewalFromLoan),
        takeEvery(ApplicationsActionsEnum.ApplicationsDashboardList, applicationsDashboardList),
        takeEvery(ApplicationsActionsEnum.ApplicationAssign, applicationAssign),
        takeEvery(ApplicationsActionsEnum.ApplicationsList, applicationsList),
        takeEvery(ApplicationsActionsEnum.ApplicationsSettlementForecastList, applicationsSettlementForecastList),
        takeEvery(ApplicationsActionsEnum.ApplicationsSettlementReportList, applicationsSettlementReportList),

        takeEvery(ApplicationsActionsEnum.ApplicationAssignPayoutFigure, applicationAssignPayoutFigure),
        takeEvery(ApplicationsActionsEnum.ApplicationClose, applicationClose),
        takeEvery(ApplicationsActionsEnum.ApplicationConditionalApproval, applicationConditionalApproval),
        takeEvery(ApplicationsActionsEnum.ApplicationDealValueSet, applicationDealValueSet),
        takeEvery(ApplicationsActionsEnum.ApplicationFormalApproval, applicationFormalApproval),
        takeEvery(ApplicationsActionsEnum.ApplicationGet, applicationGet),
        takeEvery(ApplicationsActionsEnum.ApplicationLegalDocuments, applicationLegalDocuments),
        takeEvery(ApplicationsActionsEnum.ApplicationNew, applicationNew),
        takeEvery(ApplicationsActionsEnum.ApplicationReopen, applicationReopen),
        takeLatest(ApplicationsActionsEnum.ApplicationsSearch, applicationsSearch),
        debounce(500, ApplicationsActionsEnum.ApplicationSend, applicationSend),
        takeEvery(ApplicationsActionsEnum.ApplicationSettlement, applicationSettlement),
        takeEvery(ApplicationsActionsEnum.ApplicationSettlementDate, applicationSettlementDate),
        debounce(20, ApplicationsActionsEnum.ApplicationSolicitorInstructionSentTime, applicationSolicitorInstructionsSentTime),
        takeEvery(ApplicationsActionsEnum.ApplicationUnderwriting, applicationUnderwriting),
        takeEvery(ApplicationsActionsEnum.ApplicationValueSet, applicationValueSet),
        takeEvery(ApplicationsActionsEnum.ApplicationWarehoused, applicationWarehoused),

        takeEvery(ApplicationsActionsEnum.ApplicationBorrowersAdd, applicationBorrowersAdd),
        takeEvery(ApplicationsActionsEnum.ApplicationBorrowersList, applicationBorrowersList),

        takeEvery(ApplicationsActionsEnum.ApplicationBorrowerBankruptcyCheck, applicationBorrowerBankruptcyCheck),
        debounce(20, ApplicationsActionsEnum.ApplicationBorrowerCompanyTradingHistoryCheck, applicationBorrowerCompanyTradingHistoryCheck),
        takeEvery(ApplicationsActionsEnum.ApplicationBorrowerCreditCheck, applicationBorrowerCreditCheck),
        takeEvery(ApplicationsActionsEnum.ApplicationBorrowerDelete, applicationBorrowerDelete),
        takeEvery(ApplicationsActionsEnum.ApplicationBorrowerGet, applicationBorrowerGet),
        debounce(500, ApplicationsActionsEnum.ApplicationBorrowerSend, applicationBorrowerSend),
        takeEvery(ApplicationsActionsEnum.ApplicationBorrowerValueSet, applicationBorrowerValueSet),

        takeEvery(ApplicationsActionsEnum.ApplicationConditionDelete, applicationConditionDelete),
        takeEvery(ApplicationsActionsEnum.ApplicationConditionsAdd, applicationConditionsAdd),
        takeEvery(ApplicationsActionsEnum.ApplicationConditionsList, parameterDebounce(applicationConditionsList)),

        takeEvery(ApplicationsActionsEnum.ApplicationConditionDocumentApprove, applicationConditionDocumentApprove),
        takeEvery(ApplicationsActionsEnum.ApplicationConditionDocumentDelete, applicationConditionDocumentDelete),
        takeEvery(ApplicationsActionsEnum.ApplicationConditionDocumentReject, applicationConditionDocumentReject),
        takeEvery(ApplicationsActionsEnum.ApplicationConditionDocumentResetApprovalStatus, applicationConditionDocumentResetApprovalStatus),
        takeEvery(ApplicationsActionsEnum.ApplicationConditionDocumentsAdd, applicationConditionDocumentsAdd),
        takeEvery(ApplicationsActionsEnum.ApplicationConditionDocumentDownload, applicationConditionDocumentsDownload),
        takeEvery(ApplicationsActionsEnum.ApplicationConditionDocumentsList, parameterDebounce(applicationConditionDocumentsList)),

        takeEvery(ApplicationsActionsEnum.ApplicationConditionalsStandardConditionsList, applicationConditionalConditionStandardConditionsList),

        debounce(20, ApplicationsActionsEnum.ApplicationConditionalsAdd, applicationConditionalsAdd),
        debounce(20, ApplicationsActionsEnum.ApplicationConditionalsList, applicationConditionalsList),

        takeEvery(ApplicationsActionsEnum.ApplicationConditionalApprove, applicationConditionalApprove),
        takeEvery(ApplicationsActionsEnum.ApplicationConditionalDelete, applicationConditionalDelete),
        debounce(20, ApplicationsActionsEnum.ApplicationConditionalGet, applicationConditionalGet),
        takeEvery(ApplicationsActionsEnum.ApplicationConditionalSend, applicationConditionalSend),

        takeEvery(ApplicationsActionsEnum.ApplicationConditionalConditionAdd, applicationConditionalConditionAdd),
        takeEvery(ApplicationsActionsEnum.ApplicationConditionalConditionDelete, applicationConditionalConditionDelete),

        debounce(20, ApplicationsActionsEnum.ApplicationDisbursementsAdd, applicationDisbursementsAdd),
        takeEvery(ApplicationsActionsEnum.ApplicationDisbursementsList, applicationDisbursementsList),

        takeEvery(ApplicationsActionsEnum.ApplicationDisbursementDelete, applicationDisbursementDelete),
        debounce(500, ApplicationsActionsEnum.ApplicationDisbursementSend, applicationDisbursementSend),
        takeEvery(ApplicationsActionsEnum.ApplicationDisbursementValueSet, applicationDisbursementValueSet),

        debounce(20, ApplicationsActionsEnum.ApplicationDisbursementSuggestionsList, applicationDisbursementSuggestionsList),

        debounce(20, ApplicationsActionsEnum.ApplicationFeesAdd, applicationFeesAdd),
        takeEvery(ApplicationsActionsEnum.ApplicationFeesList, applicationFeesList),

        takeEvery(ApplicationsActionsEnum.ApplicationFeeDelete, applicationFeeDelete),
        debounce(500, ApplicationsActionsEnum.ApplicationFeeSend, applicationFeeSend),
        takeEvery(ApplicationsActionsEnum.ApplicationFeeValueSet, applicationFeeValueSet),

        debounce(20, ApplicationsActionsEnum.ApplicationFormalsAdd, applicationFormalsAdd),
        debounce(20, ApplicationsActionsEnum.ApplicationFormalsList, applicationFormalsList),

        takeEvery(ApplicationsActionsEnum.ApplicationHistoriesList, applicationHistoriesList),

        takeEvery(ApplicationsActionsEnum.ApplicationNotesAdd, applicationNotesAdd),
        takeEvery(ApplicationsActionsEnum.ApplicationNotesList, applicationNotesList),

        takeEvery(ApplicationsActionsEnum.ApplicationMarkBrokerCommissionPaid, applicationMarkBrokerCommissionPaid),
        takeEvery(ApplicationsActionsEnum.ApplicationsOutstandingBrokerCommissionsList, applicationsOutstandingBrokerCommissionsList),

        takeEvery(ApplicationsActionsEnum.ApplicationGenerateMemo, applicationGenerateMemo),

        takeEvery(ApplicationsActionsEnum.ApplicationPropertyGet, applicationPropertyGet),

        debounce(20, ApplicationsActionsEnum.ApplicationPropertyValuationDueDate, applicationPropertyValuationDueDate),
        debounce(20, ApplicationsActionsEnum.ApplicationPropertyValuationInspectionDate, applicationPropertyValuationInspectionDate),
        debounce(20, ApplicationsActionsEnum.ApplicationPropertyValuationReceivedDate, applicationPropertyValuationReceivedDate),
        debounce(20, ApplicationsActionsEnum.ApplicationPropertyValuationValue, applicationPropertyValuationValue),

        debounce(20, ApplicationsActionsEnum.ApplicationPropertyValuerQuoteAmount, applicationPropertyValuerQuoteAmount),
        debounce(20, ApplicationsActionsEnum.ApplicationPropertyValuerQuoteReceived, applicationPropertyValuerQuoteReceived),
        debounce(20, ApplicationsActionsEnum.ApplicationPropertyValuerQuoteRequested, applicationPropertyValuerQuoteRequested),
        debounce(20, ApplicationsActionsEnum.ApplicationPropertyValuerQuoteTimeFrame, applicationPropertyValuerQuoteTimeFrame),
        debounce(20, ApplicationsActionsEnum.ApplicationPropertyValuerValuationOrdered, applicationPropertyValuerValuationOrdered),
        debounce(20, ApplicationsActionsEnum.ApplicationPropertyValuerValuationReceived, applicationPropertyValuerValuationReceived),

        takeEvery(ApplicationsActionsEnum.ApplicationPropertyValuersList, applicationPropertyValuersList),

        takeEvery(ApplicationsActionsEnum.ApplicationPropertyValuerMarkPaid, applicationPropertyValuerMarkPaid),

        takeEvery(ApplicationsActionsEnum.ApplicationPropertyValuersUnpaidList, applicationPropertyValuersUnpaidList),

        takeEvery(ApplicationsActionsEnum.ApplicationPropertiesList, applicationPropertiesList),
        takeEvery(ApplicationsActionsEnum.ApplicationPropertiesValuationsOutstandingList, applicationPropertiesValuationsOutstandingList),

        takeEvery(ApplicationsActionsEnum.ApplicationRfiItemDelete, applicationRfiItemDelete),
        takeEvery(ApplicationsActionsEnum.ApplicationRfiItemRequested, applicationRfiItemRequested),
        takeEvery(ApplicationsActionsEnum.ApplicationRfiItemSupplied, applicationRfiItemSupplied),

        takeEvery(ApplicationsActionsEnum.ApplicationRfiItemsAdd, applicationRfiItemsAdd),
        takeEvery(ApplicationsActionsEnum.ApplicationRfiItemsList, applicationRfiItemsList),

        takeEvery(ApplicationsActionsEnum.ApplicationWarehousesAdd, applicationWarehousesAdd),
        takeEvery(ApplicationsActionsEnum.ApplicationWarehousesList, applicationWarehousesList),

        takeEvery(ApplicationsActionsEnum.ApplicationWarehouseDelete, applicationWarehouseDelete),
        debounce(500, ApplicationsActionsEnum.ApplicationWarehouseSend, applicationWarehouseSend),
        takeEvery(ApplicationsActionsEnum.ApplicationWarehouseValueSet, applicationWarehouseValueSet),
    ]);
}
